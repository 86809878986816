import { Disclosure, Popover } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { PropsWithChildren } from 'react'

import { CaretDown, CaretRight, CirclesThree, Plus } from '@/components/Icons/v2'
import usePermissions from '@/hooks/usePermissions'

type SpacesListContainerProps = PropsWithChildren & {
  title: string
  onNewChannel?: () => void
  source: 'cohort' | 'learning-community'
  cohortId?: string
}

export function SpacesListContainer({
  title,
  children,
  onNewChannel,
  source,
  cohortId,
}: SpacesListContainerProps): JSX.Element {
  const { query } = useRouter()
  const { isManager, isOwner } = usePermissions()
  const canCreateSpace = isManager || isOwner

  const classes = {
    dropdownAction:
      'text-left px-3 py-2 flex items-center space-x-2 font-medium text-navy-dark hover:bg-grey-bright rounded-sm',
  }

  return (
    <div>
      <Disclosure defaultOpen>
        {({ open }) => {
          const chevronProps = {
            className: 'w-4 h-4',
          }

          return (
            <>
              <div className="flex items-center px-1.5 text-grey-dark">
                <Disclosure.Button className="shrink rounded-sm p-1.5 text-left hover:bg-grey-bright hover:text-navy-dark">
                  {open ? <CaretDown {...chevronProps} /> : <CaretRight {...chevronProps} />}
                </Disclosure.Button>

                <Popover className="group relative">
                  {({ open, close }) => {
                    return (
                      <>
                        <Popover.Button className="flex items-center space-x-1 rounded-sm p-1.5 text-sm hover:bg-grey-bright">
                          <div className="font-medium">{title}</div>

                          <div
                            className={classNames('mt-px group-hover:block', {
                              hidden: !open,
                            })}
                          >
                            <ChevronDownIcon className="h-4" />
                          </div>
                        </Popover.Button>

                        <Popover.Panel className="absolute z-50 w-48 pt-3">
                          <div className="rounded-md border border-grey-extra-light bg-white p-1 shadow">
                            <div className="flex flex-col">
                              {canCreateSpace ? (
                                <button onClick={() => onNewChannel?.()} className={classes.dropdownAction}>
                                  <Plus className="h-4 w-4 text-grey-dark" />
                                  <div>{t`Create space`}</div>
                                </button>
                              ) : null}

                              <Link
                                href={`/${query.slug}/learn/${cohortId}/space/discover?source=${source}`}
                                className={classes.dropdownAction}
                                onClick={close}
                              >
                                <CirclesThree className="h-4 w-4 text-grey-dark" />
                                <div>{t`Discover spaces`}</div>
                              </Link>
                            </div>
                          </div>
                        </Popover.Panel>
                      </>
                    )
                  }}
                </Popover>
              </div>

              <Disclosure.Panel>{children}</Disclosure.Panel>
            </>
          )
        }}
      </Disclosure>
    </div>
  )
}
