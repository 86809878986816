const SvgIconX = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.172 8 .586 3.414 3.414.586 8 5.172 12.586.586l2.828 2.828L10.83 8l4.585 4.586-2.828 2.828L8 10.828l-4.586 4.586-2.828-2.828L5.172 8Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconX
