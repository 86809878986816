import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { RoleNameEnum } from '@/graphql/generated'
import { getPathRoles } from '@/helpers/pathSettings'

type ReturnType = {
  loading: boolean
  isOwner?: boolean
  isManager?: boolean
  isLearner?: boolean
  isAssistant?: boolean
  isCollaborator?: boolean
  isGuest?: boolean
  restrictedAccess: boolean | undefined
  role: string | undefined
  hasLearningAccess: boolean
  hasManagementAccess: boolean
}

const usePermissions = (): ReturnType => {
  const {
    pathname,
    isReady,
    query: { slug, cohortId },
  } = useRouter()
  const userContext = useCurrentUserContext()
  const [roles, setRoles] = useState<Array<string | undefined>>()
  const [restrictedAccess, setRestrictedAccess] = useState<boolean>()

  const isRole = (role: string): boolean | undefined => roles?.includes(role.toUpperCase())

  useEffect(() => {
    if (!userContext || !isReady) return

    // When there's no slug, we need to grab all user's roles
    if (!slug) {
      setRoles(userContext?.currentUser?.me?.accounts?.map(({ role }) => role?.name.toUpperCase()))

      return
    }

    // When there's a slug available, we need to dive through the
    // learning communities and set the correct role.
    const account = userContext?.currentUser?.me?.accounts?.find(
      ({ learningCommunity }) => learningCommunity.slug === slug,
    )

    // When user has no role in the current LC we need to restrict the access
    if (!account) {
      setRestrictedAccess(true)
      return
    }

    const currentRole = account?.role?.name?.toUpperCase() ?? RoleNameEnum.Learner

    // When Learner doesn't belong to current cohort we need to restrict the access
    if (
      cohortId &&
      currentRole === RoleNameEnum.Learner &&
      !account.learningCommunity?.cohorts?.find(cohort => cohort.id === cohortId)
    ) {
      setRestrictedAccess(true)
      return
    }

    setRoles([currentRole])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext, slug, isReady])

  useEffect(() => {
    if (!roles?.length) return

    // Get permissions
    const permissions = getPathRoles(pathname)

    // Get permissions access by path
    const access = permissions?.some(permission => roles.includes(permission.toUpperCase()))

    setRestrictedAccess(!access)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles])

  return {
    loading: !userContext,
    isOwner: isRole(RoleNameEnum.Owner),
    isManager: isRole(RoleNameEnum.SuccessManager),
    isLearner: isRole(RoleNameEnum.Learner),
    isAssistant: isRole(RoleNameEnum.Assistant),
    isCollaborator: isRole(RoleNameEnum.Collaborator),
    isGuest: isRole(RoleNameEnum.Guest),
    role: roles?.[0],
    restrictedAccess,
    hasLearningAccess: !!(isRole(RoleNameEnum.Learner) || isRole(RoleNameEnum.Guest)),
    hasManagementAccess: !!(
      isRole(RoleNameEnum.Owner) ||
      isRole(RoleNameEnum.SuccessManager) ||
      isRole(RoleNameEnum.Assistant) ||
      isRole(RoleNameEnum.Collaborator)
    ),
  }
}

export default usePermissions
