import { EmojiClickData, EmojiStyle, Props as EmojiPickerProps } from 'emoji-picker-react'
import dynamic from 'next/dynamic'
import { useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'

const EmojiPickerNoSSRWrapper = dynamic<EmojiPickerProps>(() => import('emoji-picker-react'), {
  ssr: false,
})

type Props = {
  onEmojiClick: (data: EmojiClickData) => void
  onClose: () => void
  disableAutofocus?: boolean
  searchPlaceholder?: string
}

const EmojiPicker: React.FC<React.PropsWithChildren<Props>> = ({
  onEmojiClick,
  disableAutofocus,
  searchPlaceholder = '',
  onClose,
}) => {
  const ref = useRef(null)
  useOnClickOutside(ref, onClose)

  return (
    <div ref={ref}>
      <EmojiPickerNoSSRWrapper
        onEmojiClick={(data: EmojiClickData) => onEmojiClick(data)}
        autoFocusSearch={disableAutofocus}
        searchPlaceHolder={searchPlaceholder}
        emojiStyle={EmojiStyle.NATIVE}
      />
    </div>
  )
}

export default EmojiPicker
