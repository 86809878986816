/* istanbul ignore file */
import { useRouter } from 'next/router'

export type ReturnType = {
  isSpacePath?: boolean
}

export function usePaths(): ReturnType {
  const { pathname } = useRouter()

  return {
    isSpacePath: pathname === '/[slug]/learn/[cohortId]/space/[channelId]',
  }
}
