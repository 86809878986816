import { t } from '@lingui/macro'
import { useRouter } from 'next/router'

import MainMenuItem from '@/components/MainMenuItem/MainMenuItem'
import SpaceSymbol from '@/compositions/Spaces/SpaceSymbol'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import { ChannelTypeEnum } from '@/graphql/generated'

export default function LearningCommunitySpacesList(): JSX.Element {
  const { query, asPath } = useRouter()
  const learningCommunityContext = useLearningCommunityContext()
  const channels = learningCommunityContext?.learningCommunity?.channels

  if (!channels?.length) {
    return (
      <div className="p-4">
        <p className="text-gray-400 text-sm">{t`No spaces yet.`}</p>
      </div>
    )
  }

  return (
    <ul className="pl-4">
      {channels
        ?.filter(channel => channel.type !== ChannelTypeEnum.AskYtChannel)
        ?.map(channel => {
          const { id, viewer, emojiIcon, name } = channel
          const href = `/${query.slug}/space/${id}`

          return (
            <MainMenuItem
              key={id}
              isActive={asPath === href}
              icon={<SpaceSymbol emojiIcon={emojiIcon} size="sm" />}
              href={href}
              label={name as string}
              indicator={viewer?.hasUnreadMessages}
            />
          )
        })}
    </ul>
  )
}
