import { t } from '@lingui/macro'
import { FloatingNotificationInbox, INotification, useNotification } from '@magicbell/magicbell-react'
import { NotificationListItem } from '@magicbell/magicbell-react/dist/components/NotificationList/NotificationList'
import axios from 'axios'
import formatDistance from 'date-fns/formatDistance'
import { sanitize } from 'isomorphic-dompurify'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Events } from '@/constants'
import useTracking from '@/hooks/useTracking'

import { Avatar } from '..'
import { IconBell } from '../Icons'

const MagicBell = dynamic(() => import('@magicbell/magicbell-react'), { ssr: false })

const theme = {
  icon: { width: '16px' },
  header: { backgroundColor: '#fff', textColor: '#0E141C', borderRadius: '16px' },
  footer: { backgroundColor: '#fff', textColor: '#B0B0B0', borderRadius: '16px' },
  notification: {
    default: { textColor: '#15091F', borderRadius: '8px', backgroundColor: '#4B6CC2' },
    unseen: { backgroundColor: '#4B6CC2', textColor: '#15091F', borderRadius: '8px' },
    unread: { backgroundColor: '#4B6CC2', textColor: '#15091F', borderRadius: '8px' },
  },
}

type Props = {
  email: string
}

type WindowWithHooks = typeof window & {
  __MAGICBELL_HOOKS__: {
    axios: unknown
  }
}

const CustomNotification: NotificationListItem = ({ notification: data, onClick }) => {
  const notification = useNotification(data) as INotification & {
    customAttributes: {
      graphic: string
    }
  }
  const { trackEvent, withLearningCommunitySlugFromQuery, withCohortIdFromQuery } = useTracking()
  const router = useRouter()

  const handleClick = (): void => {
    notification.markAsRead()
    trackEvent(Events.CLICKED_NOTIFICATION_ITEM, withLearningCommunitySlugFromQuery(), withCohortIdFromQuery())
    onClick?.(notification)

    if (notification.actionUrl) {
      router.push(notification.actionUrl)
    }
  }

  return (
    <div onClick={handleClick} role="button" tabIndex={-1} className="!my-2 bg-white !px-2">
      <div className="flex !w-full cursor-pointer items-start space-x-4 !break-words !rounded-sm bg-grey-bright !p-4 hover:bg-purple-bright">
        {notification.customAttributes?.graphic ? (
          <div className="">
            <Avatar bordered size="xl" url={notification.customAttributes.graphic} />
          </div>
        ) : null}

        <div className="w-full space-y-2">
          <div className="flex !w-full flex-row items-start justify-between">
            <p
              className="!font-medium !text-navy-dark !font-sans !text-base !capsize"
              dangerouslySetInnerHTML={{ __html: sanitize(notification.title) }}
            />
            {notification.isRead ? null : <div className="h-2.5 w-2.5 rounded-full bg-green-base" />}
          </div>
          <p
            className="!text-grey-dark !text-sm"
            dangerouslySetInnerHTML={{ __html: sanitize(notification.content || '') }}
          />
          {notification.seenAt ? (
            <p className="!text-grey-base !text-sm">
              {formatDistance(new Date(notification.sentAt as unknown as string), new Date(), { addSuffix: true })}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const NotificationsWidget: React.FC<React.PropsWithChildren<Props>> = ({ email }) => {
  const [disabled, setDisabled] = useState(false)
  const { trackEvent } = useTracking()

  useEffect(() => {
    if (!window) return

    const client = axios.create()

    client.interceptors.response.use(
      response => response,
      function (errorMessage) {
        setDisabled(true)

        trackEvent(Events.CAPTURED_MAGIC_BELL_ERROR, {
          errorMessage,
          userEmail: email,
        })

        return false
      },
    )
    ;(window as WindowWithHooks)['__MAGICBELL_HOOKS__'] = { axios: client }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return disabled ? null : (
    <button className="notifications-button">
      <MagicBell
        BellIcon={
          <div className="flex grow items-center space-x-2 !font-sans !text-base">
            <div className="flex !w-6 justify-center">
              <IconBell className="h-4 w-4" />
            </div>

            <div className="grow !text-sm">{t`Notifications`}</div>
          </div>
        }
        Badge={({ count }) =>
          count > 0 ? (
            <div className="unread-badge">
              <div className="!-mt-px">{count}</div>
            </div>
          ) : (
            <></>
          )
        }
        theme={theme}
        apiKey={process.env.NEXT_PUBLIC_MAGICBELL_API_KEY}
        userEmail={email}
      >
        {props => (
          <FloatingNotificationInbox
            height={400}
            width={500}
            placement="right-end"
            NotificationItem={CustomNotification}
            closeOnNotificationClick
            {...props}
          />
        )}
      </MagicBell>
    </button>
  )
}

export default NotificationsWidget
