const SvgIconUsers = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      d="M8 3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM3 8a3 3 0 0 0-3 3v3h8V8H3Zm10 0h-3v6h6v-3a3 3 0 0 0-3-3Zm-1-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </svg>
)

export default SvgIconUsers
