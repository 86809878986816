import { gql, useApolloClient, useQuery } from '@apollo/client'
import { addDays } from 'date-fns'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { NUMBER_OF_DAYS_BEFORE_SECTION_TO_SHOW_AVAILABILITY_BANNER } from '@/constants'
import { CohortTypeEnum, LearningPath, LearningPathLiveSessionBlock, Query } from '@/graphql/generated'
import { isBetween } from '@/utils/date'

type ReturnType = {
  loading: boolean
  learningPath?: LearningPath
  dynamicSquadSessions?: DynamicSquadSessions
}

export type DynamicSquadSessions = { [key: string]: LearningPathLiveSessionBlock[] }

const useLearningPath = (): ReturnType => {
  const { query, isReady } = useRouter()
  const client = useApolloClient()
  const [dynamicSquadSessions, setDynamicSquadSessions] = useState<DynamicSquadSessions>()

  const { data, loading } = useQuery<Pick<Query, 'learningPath' | 'cohort'>>(GET_LEARNING_PATH_DATA, {
    skip: !isReady,
    variables: { cohortId: query.cohortId?.toString() ?? '' },
    notifyOnNetworkStatusChange: true,
    onCompleted: async data => {
      if (data.cohort?.hasDynamicSquads) {
        const sectionsToFetchDynamicSquads = data.learningPath.sections.filter(section => {
          if (section.cohort.type === CohortTypeEnum.Async) return section

          const isUpcomingSection = isBetween({
            date: new Date(section.schedule?.startDate),
            compareToEnd: addDays(new Date(), NUMBER_OF_DAYS_BEFORE_SECTION_TO_SHOW_AVAILABILITY_BANNER),
            compareToStart: new Date(),
          })

          const isSectionInProgress = isBetween({
            date: new Date(),
            compareToEnd: new Date(section.schedule?.endDate),
            compareToStart: new Date(section.schedule?.startDate),
          })

          return isUpcomingSection || isSectionInProgress
        })

        if (sectionsToFetchDynamicSquads.length) {
          const dynamicSquadSessionsResponse = await Promise.all(
            sectionsToFetchDynamicSquads.map(section =>
              client.query({
                query: GET_DYNAMIC_SQUAD_SESSIONS,
                variables: {
                  sectionId: section.id,
                },
                fetchPolicy: 'no-cache',
              }),
            ),
          )

          setDynamicSquadSessions(
            dynamicSquadSessionsResponse.reduce((acc, curr, index) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              acc[sectionsToFetchDynamicSquads[index].id] = curr.data.liveSessionsForDynamicSquads
              return acc
            }, {}),
          )
        }
      }
    },
  })

  return {
    learningPath: data?.learningPath,
    loading,
    dynamicSquadSessions,
  }
}

const GET_LEARNING_PATH_DATA = gql`
  query GetLearningPathData($cohortId: ID!) {
    learningPath(cohortId: $cohortId) {
      project {
        id
        title
        viewer {
          progress {
            submittedCount
            totalCount
            certificate {
              imageUrl
              linkedinShareUrl
              twitterShareUrl
            }
          }
        }
      }
      sections {
        id
        title
        isFree
        schedule {
          endDate
          startDate
          timeCommitmentInMinutes
        }
        viewerStatus {
          isComplete
          progress
        }
        blocks {
          id
          title
          schedule {
            endsAt
            startsAt
            timeCommitmentInMinutes
          }
          viewerStatus {
            markedAsDoneAt
          }
          type {
            displayValue
            identifier
          }
          ... on LearningPathMilestoneBlock {
            position
            submissionDeadline
          }
          ... on LearningPathLiveSessionBlock {
            isOptionForDynamicSquads
          }
        }
        cohort {
          id
          type
        }
      }
    }
    cohort(id: $cohortId) {
      hasDynamicSquads
    }
  }
`

const GET_DYNAMIC_SQUAD_SESSIONS = gql`
  query LiveSessionsForDynamicSquads($sectionId: ID!) {
    liveSessionsForDynamicSquads(sectionId: $sectionId) {
      id
      schedule {
        startsAt
        endsAt
        timeCommitmentInMinutes
      }
      title
      body {
        rawContent
      }
      peopleAttending {
        id
        fullName
        avatarUrl
        jobDescription
        firstName
      }
    }
  }
`

export default useLearningPath
