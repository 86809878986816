import classNames from 'classnames'

import { IconCheck, IconClock, IconLock } from '@/components/Icons'
import { useBlockTypeInfo } from '@/constants'
import { LearningPathBlockTypeEnum } from '@/graphql/generated'

type Props = {
  blockType: LearningPathBlockTypeEnum
  isBlockCompleted: boolean
  size?: 'sm' | 'md'
  isCurrentBlock?: boolean
  locked?: boolean
  isOverdue?: boolean
}

const BlockIcon: React.FC<React.PropsWithChildren<Props>> = ({
  blockType,
  isBlockCompleted,
  isCurrentBlock,
  isOverdue = false,
  size = 'sm',
  locked = false,
}) => {
  const BlockTypesInfo = useBlockTypeInfo()
  const Icon = BlockTypesInfo[blockType].icon

  return (
    <div
      className={classNames('relative flex flex-none items-center justify-center', {
        'bg-purple-base text-white': isCurrentBlock,
        'bg-purple-bright text-purple-base': !isCurrentBlock,
        'h-12 w-12 rounded-md': size === 'sm',
        'h-8 w-8 rounded-base md:h-16 md:w-16': size === 'md',
      })}
    >
      <Icon className={size === 'sm' ? 'icon-sm' : 'h-4 w-4 md:h-6 md:w-6'} />
      {isBlockCompleted ? (
        <IconBadge size={size} backgroundColor="bg-green-dark" icon={IconCheck} />
      ) : locked ? (
        <IconBadge size={size} backgroundColor="bg-red-dark" icon={IconLock} />
      ) : isOverdue ? (
        <IconBadge size={size} backgroundColor="bg-yellow-dark" icon={IconClock} />
      ) : null}
    </div>
  )
}

type BadgeProps = {
  size: 'sm' | 'md'
  backgroundColor: string
  icon: React.ComponentType<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>
}

const IconBadge: React.FC<React.PropsWithChildren<BadgeProps>> = ({ size, backgroundColor, icon: Icon }) => {
  const classes = {
    container: classNames(
      'absolute p-1 rounded-full text-white',
      {
        '-right-1 -bottom-1': size === 'sm',
        '-right-1.5 -bottom-1.5 md:': size === 'md',
      },
      backgroundColor,
    ),
    icon: classNames({
      'h-2 w-2': size === 'sm',
      'h-3 w-3': size === 'md',
    }),
  }

  return (
    <span className={classes.container}>
      <Icon className={classes.icon} />
    </span>
  )
}

export default BlockIcon
