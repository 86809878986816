import classNames from 'classnames'

import { IconFrame } from '@/components/Icons'
import { Channel } from '@/graphql/generated'

type Props = Partial<Channel> & {
  size?: 'sm' | 'base'
}

export default function SpaceSymbol({ emojiIcon, size = 'base' }: Props): JSX.Element {
  const classes = {
    container: classNames('leading-none', size === 'base' ? 'h-7 w-7' : 'h-4 w-4'),
    icon: classNames(size === 'base' ? 'h-5 w-5' : 'h-4 w-4'),
  }

  if (emojiIcon) {
    const fontSize = classNames(size === 'base' ? 'text-2xl' : 'text-sm')

    return <div className={classNames(classes.container, fontSize)}>{emojiIcon}</div>
  }

  return (
    <div className={classes.container}>
      <IconFrame className={classes.icon} />
    </div>
  )
}
