export enum ScreenSize {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
}

export const getScreenSize = (): number => {
  return window?.innerWidth ?? 0
}
