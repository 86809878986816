const SvgIconCheck = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.414 4.414 6 13.828.586 8.414l2.828-2.828L6 8.172l6.586-6.586 2.828 2.828Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconCheck
