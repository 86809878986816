import { t } from '@lingui/macro'
import Script from 'next/script'

import { Button, Text } from '@/components'
import LCLogo from '@/components/LCLogo'
import { LearningCommunity } from '@/graphql/generated'
import useRoutes from '@/hooks/useRoutes'

type Props = {
  learningCommunity?: Partial<LearningCommunity>
}

const Public: React.FC<React.PropsWithChildren<Props>> = ({ children, learningCommunity }) => {
  const { routes } = useRoutes()

  return (
    <>
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`} />
      <Script id="gtm">
        {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');`}
      </Script>

      <div className="flex min-h-screen flex-col justify-between">
        <header className="bg-white py-8 px-8 md:h-auto md:px-16">
          <div className="flex justify-between">
            <div className="flex items-center space-x-9">
              {learningCommunity ? <LCLogo size="lg" learningCommunity={learningCommunity} /> : null}
              <Text variant={TextStyle => TextStyle.REGULAR_2XL_NORMAL} className="text-grey-dark">
                {learningCommunity?.name}
              </Text>
            </div>
            <div className="flex items-center">
              <div className="hidden items-center space-x-4 md:flex">
                <Text>{t`Already a member?`}</Text>
                <Button href={routes.LOGIN} variant="primary" newTab type="link" excludeIcon>
                  {t`Sign in`}
                </Button>
              </div>
            </div>
          </div>
        </header>

        <div className="h-full flex-grow">{children}</div>

        <footer className="bg-grey-bright px-6 py-4">
          <div className="flex flex-col space-y-4 md:flex-row md:justify-between md:space-y-0">
            <Text
              variant={TextStyle => TextStyle.REGULAR_SM_NORMAL}
              className="text-center text-grey-dark md:text-left"
            >
              Copyright &copy; Coleap Technology GmbH {new Date().getFullYear()}
            </Text>
            <div className="flex justify-center space-x-6 text-grey-dark text-sm">
              <a target="_blank" href="https://www.coleap.com/terms" rel="noreferrer">
                {t`Terms and conditions`}
              </a>
              <a target="_blank" href="https://www.coleap.com/imprint" rel="noreferrer">
                {t`Imprint`}
              </a>
              <a target="_blank" href="https://www.coleap.com/privacy" rel="noreferrer">
                {t`Privacy Policy`}
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Public
