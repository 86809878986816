import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import { CaretDown, CaretRight } from '@/components/Icons/v2'
import { useCohortContext } from '@/contexts/CohortContext'
import { CustomCohort } from '@/types/custom'

type SpacesListContainerProps = PropsWithChildren & {
  cohort: CustomCohort
}

export default function CohortMenuContainer({ cohort, children }: SpacesListContainerProps): JSX.Element {
  const cohortContext = useCohortContext()

  return (
    <div>
      <Disclosure defaultOpen>
        {({ open }) => {
          const chevronProps = {
            className: 'w-4 h-4',
          }

          return (
            <>
              <div
                className={classNames('flex items-center rounded-md bg-grey-extra-light px-1.5 text-grey-dark', {
                  'bg-purple-base text-white': cohort.id === cohortContext?.cohort?.id,
                })}
              >
                <Disclosure.Button className="shrink rounded-sm p-1.5 text-left hover:bg-grey-bright hover:text-navy-dark">
                  {open ? <CaretDown {...chevronProps} /> : <CaretRight {...chevronProps} />}
                </Disclosure.Button>
                <div className="p-1.5 font-medium text-sm">{cohort.name}</div>
              </div>

              <Disclosure.Panel>{children}</Disclosure.Panel>
            </>
          )
        }}
      </Disclosure>
    </div>
  )
}
