import { Popover } from '@headlessui/react'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { Float } from 'headlessui-float-react'
import groupBy from 'lodash/groupBy'
import Link from 'next/link'
import { PropsWithChildren } from 'react'

import { Button, Text } from '@/components'
import LCLogo from '@/components/LCLogo/LCLogo'
import { Routes } from '@/constants'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import { RoleNameEnum } from '@/graphql/generated'

export default function LearningCommunitySwitcher({ children }: PropsWithChildren): JSX.Element {
  const userContext = useCurrentUserContext()
  const learningCommunityContext = useLearningCommunityContext()

  const data = groupBy(userContext?.currentUser?.me?.accounts, account => account.role?.displayValue)

  return (
    <div>
      <Popover className="relative text-left">
        {({ open, close }) => (
          <Float
            placement="right-start"
            offset={8}
            strategy="fixed"
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Button className="w-full">{children}</Popover.Button>

            {open ? (
              <Popover.Panel
                static
                className="no-scrollbar max-h-[90vh] w-[600px] space-y-1 overflow-y-auto rounded-md border border-grey-bright bg-white p-2 shadow-2xl shadow-grey-light"
              >
                <div className="space-y-6">
                  {Object.keys(data).map(roleName => {
                    const accounts = data[roleName]

                    return (
                      <div key={roleName}>
                        <div className="p-2">
                          <Text variant={TextStyle => TextStyle.MEDIUM_LG_NORMAL}>{roleName}</Text>
                        </div>

                        {accounts?.map(account => {
                          const { learningCommunity, role } = account

                          const canManage =
                            role?.name === RoleNameEnum.Owner || role?.name === RoleNameEnum.SuccessManager
                          const isCurrentLC = learningCommunity.id === learningCommunityContext?.learningCommunity?.id

                          return (
                            <div
                              key={learningCommunity.id}
                              className={classNames('flex items-center space-x-2 rounded-md p-2', {
                                'bg-purple-base font-medium text-white': isCurrentLC,
                                'hover:bg-grey-extra-light': !isCurrentLC,
                              })}
                            >
                              <Link
                                onClick={close}
                                href={`/${learningCommunity.slug}`}
                                passHref
                                className="text-md flex w-full grow items-center space-x-2"
                              >
                                <div className="flex grow items-center space-x-2 text-base">
                                  <LCLogo size="lg" learningCommunity={learningCommunity} />
                                  <div>{learningCommunity?.name}</div>
                                </div>
                              </Link>

                              <div className="flex flex-1">
                                {canManage ? (
                                  <Button
                                    type="link"
                                    href={`/${learningCommunity.slug}${Routes.MANAGEMENT}`}
                                    size="xs"
                                    variant={isCurrentLC ? 'secondary' : 'inverted'}
                                    excludeIcon
                                  >
                                    {t`Manage`}
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </Popover.Panel>
            ) : (
              <></>
            )}
          </Float>
        )}
      </Popover>
    </div>
  )
}
