import { t } from '@lingui/macro'
import classNames from 'classnames'
import sortBy from 'lodash/sortBy'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Button, Dropdown, Tag } from '@/components'
import {
  IconActivity,
  IconArrowDownRight,
  IconChevronDown,
  IconExternalLink,
  IconInbox,
  IconMail,
  IconPlus,
  IconRoute,
  IconSettings,
} from '@/components/Icons'
import { Routes } from '@/constants'
import { Cohort, CohortStatusEnum } from '@/graphql/generated'
import useFlags from '@/hooks/useFlags'

type Props = {
  cohorts: Cohort[]
}
const CohortNavigation: React.FC<Props> = ({ cohorts }) => {
  const { query, pathname, replace, push } = useRouter()
  const { viewEmailTemplates } = useFlags()

  const getInitialSelectedCohort = (): Cohort | undefined => {
    let cohort

    if (query?.cohortId) {
      cohort = cohorts?.find(({ id }) => id === query.cohortId)
      setSelectedCohort?.(cohort)

      return cohort
    }

    const runningCohorts = cohorts?.find(cohort => cohort.status === CohortStatusEnum.Running)

    cohort = runningCohorts ? runningCohorts : cohorts?.[0]
    setSelectedCohort?.(cohort)

    return cohort
  }

  const [selectedCohort, setSelectedCohort] = useState<Cohort | undefined>(getInitialSelectedCohort())

  const menu = [
    {
      icon: IconRoute,
      path: `/${query?.slug}/manage/${selectedCohort?.id}${Routes.LEARNING_PATH}`,
      label: t`Learning Path`,
      visible: true,
    },
    {
      icon: IconMail,
      path: `/${query?.slug}/manage/${selectedCohort?.id}${Routes.EMAIL_TEMPLATES}`,
      label: t`Email Templates`,
      visible: viewEmailTemplates,
    },
    {
      icon: IconInbox,
      path: `/${query?.slug}/manage/${selectedCohort?.id}${Routes.SUBMISSIONS}`,
      label: t`Submissions`,
      visible: true,
    },
    {
      icon: IconActivity,
      path: `/${query?.slug}/manage/${selectedCohort?.id}${Routes.ANALYTICS}`,
      label: t`Analytics`,
      visible: true,
    },
    {
      icon: IconExternalLink,
      path: `/${query?.slug}${Routes.LEARN}/${selectedCohort?.id}`,
      label: t`Learning community`,
      visible: true,
    },
    {
      icon: IconSettings,
      path: `/${query?.slug}/manage/${selectedCohort?.id}/edit`,
      label: t`Settings`,
      visible: true,
    },
  ]
    .filter(Boolean)
    .filter(({ visible }) => visible)

  useEffect(() => {
    if (cohorts.length) {
      getInitialSelectedCohort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohorts])

  useEffect(() => {
    if (!selectedCohort || !query.cohortId) return

    replace({
      query: { ...query, cohortId: selectedCohort.id },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCohort])

  return (
    <div className="mt-4">
      <div className="relative m-2 rounded-md px-4 py-1 hover:bg-black hover:bg-opacity-10">
        <Dropdown
          label={
            <div className="flex w-full items-center justify-between text-white">
              <div className="grow text-left">
                <div className="text-left font-medium uppercase text-white text-sm">{t`Experiences`}</div>
                <div className="flex h-8 items-center space-x-3 text-grey-extra-light">
                  <IconArrowDownRight className="icon-sm fill-current" />
                  <div className="font-sans text-base capsize">{selectedCohort?.name ?? ''}</div>
                </div>
              </div>

              <div className="float-right inline-block">
                <IconChevronDown className="icon-sm fill-current text-white" />
              </div>
            </div>
          }
          fullWidth
          dropdownWidth="w-72"
        >
          {sortBy(cohorts, 'name').map(cohort => (
            <Button
              key={cohort.id}
              align="start"
              block
              variant="inline"
              type="button"
              onClick={() => setSelectedCohort(cohort)}
            >
              <div className="flex w-full items-center">
                <div className="h-full grow truncate py-1 text-left">{cohort.name}</div>
                <Tag type="info">
                  <div className="capitalize">{cohort.type.toLowerCase()}</div>
                </Tag>
              </div>
            </Button>
          ))}

          <Button
            align="start"
            block
            variant="inverted"
            size="sm"
            type="button"
            icon={IconPlus}
            onClick={() => push(`/${query.slug}/manage/new-cohort`)}
          >
            {t`New experience`}
          </Button>
        </Dropdown>
      </div>

      <ul className="space-y-0.5">
        {selectedCohort
          ? menu.map(({ path, icon: Icon, label }, index) => {
              // Skip submissions menu if selectedCohort doesn't have a project
              if (path.includes(Routes.SUBMISSIONS) && !selectedCohort?.learningPath?.project) return

              // Ignore slug & cohortId from paths
              const [, , , , asPath] = path.split('/')
              const [, , , , asPathname] = pathname.split('/')
              const isCurrentPath = asPath && asPathname ? asPath === asPathname : false
              const anchor = classNames(
                'rounded-md space-x-2 px-4 hover:bg-black hover:bg-opacity-10 py-2 leading-none mx-2 flex items-center block text-white',
                isCurrentPath ? 'bg-black bg-opacity-10' : '',
              )
              const svg = classNames('icon-sm fill-current mt-0.5 text-white')

              return (
                <li key={index}>
                  <Link passHref href={path} className={anchor}>
                    <Icon className={svg} />
                    <div>{label}</div>
                  </Link>
                </li>
              )
            })
          : null}
      </ul>
    </div>
  )
}

export default CohortNavigation
