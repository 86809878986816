import { t } from '@lingui/macro'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Avatar, Dropdown, NotificationsWidget } from '@/components'
import { IconExternalLink, IconLogOut, IconUser } from '@/components/Icons'
import { Routes } from '@/constants'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import useAuth from '@/hooks/useAuth'

const LearnerBottomNavigation: React.FC<React.PropsWithChildren> = () => {
  const { query } = useRouter()
  const userContext = useCurrentUserContext()
  const learningCommunityContext = useLearningCommunityContext()
  const { logOut } = useAuth()

  return (
    <div className="flex w-full flex-col text-sm">
      {userContext?.currentUser?.me?.email ? <NotificationsWidget email={userContext?.currentUser?.me?.email} /> : null}

      {userContext?.currentUser?.me ? (
        <Dropdown
          label={
            <div className="flex w-full items-center space-x-2 p-3 text-grey-dark hover:text-navy-dark">
              <div>
                <Avatar
                  url={userContext?.currentUser?.me?.avatarUrl ?? ''}
                  size="md"
                  name={userContext?.currentUser?.me?.fullName ?? ''}
                />
              </div>

              <div>{userContext?.currentUser?.me?.fullName}</div>
            </div>
          }
          position="right"
          withShadow
        >
          {query?.cohortId ? (
            <Link
              href={
                query?.cohortId
                  ? {
                      pathname: `/[slug]${Routes.LEARN}/[cohortId]${Routes.PROFILE}`,
                      query: { cohortId: query?.cohortId, slug: query?.slug },
                    }
                  : '#'
              }
              passHref
              className="mb-1 flex w-full items-center space-x-3 rounded-md p-3 text-left hover:bg-grey-extra-light"
            >
              <IconUser className="h-4 w-4" />
              <div className="font-medium font-sans text-base-none capsize">{t`View profile`}</div>
            </Link>
          ) : null}

          <a
            className="mb-1 flex h-10 w-full items-center space-x-3 rounded-md p-3 text-left hover:bg-grey-extra-light"
            href={`mailto:${
              learningCommunityContext?.learningCommunity?.supportEmail
            }?cc=support@coleap.com&subject=${encodeURIComponent(
              `Help & Support - ${learningCommunityContext?.learningCommunity?.name}`,
            )}`}
          >
            <IconExternalLink className="icon-sm" />
            <div className="font-medium font-sans text-base-none capsize">{t`Help & Support`}</div>
          </a>

          <button
            onClick={() => logOut(Routes.LOGIN)}
            className="flex h-10 w-full items-center space-x-3 rounded-md p-3 text-left hover:bg-grey-extra-light"
          >
            <IconLogOut className="h-4 w-4" />
            <div className="font-medium font-sans text-base-none capsize">Log out</div>
          </button>
        </Dropdown>
      ) : null}
    </div>
  )
}

export default LearnerBottomNavigation
