/* istanbul ignore file */
import { t } from '@lingui/macro'

import { IconActivity, IconCalendar, IconHouse, IconLink, IconRoute, IconUsers, IconVideo } from '@/components/Icons'
import { Routes } from '@/constants'

type GetSidebarMenusProps = {
  viewHome?: boolean
  showAskMyYtChannel?: boolean
}

export type SidebarMenuItemType = {
  path: string
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  label: string
  visible?: boolean
  hideOnMobile?: boolean
}

export type SidebarMenuType = {
  learningCommunityMenus: SidebarMenuItemType[]
  cohortMenus: SidebarMenuItemType[]
}

export function useSidebarMenus({ viewHome, showAskMyYtChannel }: GetSidebarMenusProps = {}): SidebarMenuType {
  const cohortPath = `/[slug]${Routes.LEARN}/[cohortId]`

  return {
    learningCommunityMenus: [
      {
        path: `/[slug]`,
        icon: IconHouse,
        label: t`Home`,
        visible: true,
      },
      {
        path: '/[slug]/events',
        icon: IconCalendar,
        label: t`Events`,
        visible: true,
      },
      {
        path: '/[slug]/ask',
        icon: IconVideo,
        label: t`Ask My YouTube Channel`,
        visible: showAskMyYtChannel,
      },
    ],
    cohortMenus: [
      {
        path: `${cohortPath}${Routes.DASHBOARD}`,
        icon: IconHouse,
        label: t`Home`,
        visible: viewHome,
      },
      {
        path: `${cohortPath}`,
        icon: IconRoute,
        label: t`Learning path`,
        visible: true,
      },
      {
        path: `${cohortPath}${Routes.FEED}`,
        icon: IconActivity,
        label: t`Activity`,
        visible: !viewHome,
      },
      {
        path: `${cohortPath}${Routes.COMMUNITY}`,
        icon: IconUsers,
        label: t`Community`,
        visible: true,
      },
      {
        path: `${cohortPath}${Routes.RESOURCES}`,
        icon: IconLink,
        label: t`Resources`,
        visible: true,
      },
    ],
  }
}
