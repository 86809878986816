import LearnerLayout from '@/containers/Layouts/Learner'
import OwnerLayout from '@/containers/Layouts/Owner'
import PrintLayout from '@/containers/Layouts/Print/Print'
import PublicLayout from '@/containers/Layouts/Public'
import { RoleNameEnum } from '@/graphql/generated'
import { Layout } from '@/types/custom'

const Layouts: { [key: string]: React.FC<React.PropsWithChildren> } = {
  [RoleNameEnum.Owner]: OwnerLayout,
  [RoleNameEnum.Learner]: LearnerLayout,
  ONBOARDING: LearnerLayout,
  PUBLIC: PublicLayout,
  PRINT: PrintLayout,
}

type PathSettingsProps = {
  [key: string]: {
    layout: React.FC<React.PropsWithChildren>
    roles: string[]
  }
}

// This needs to be updated whenever we create a new page.
const pathSettings: PathSettingsProps = {
  '/onboarding': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/manage/welcome': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/crm': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/waiting-list': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/team': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/settings': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/integrations': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/application-form': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/new-cohort': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/events': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/ask': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/events/manage': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/events/[eventId]': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/feed': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/feed/[feedEntryId]': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/space/[channelId]': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/space/[channelId]': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/space/discover': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/community': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/profile': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/resources': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/profile/invoices': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/blocks/[blockId]': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/onboarding/profile': {
    layout: Layouts.ONBOARDING,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/onboarding/extra': {
    layout: Layouts.ONBOARDING,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/onboarding/picture': {
    layout: Layouts.ONBOARDING,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/submission/[submissionId]': {
    layout: Layouts.PRINT,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/onboarding/introduction': {
    layout: Layouts.ONBOARDING,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/channels/[channelId]': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/learn/[cohortId]/dashboard': {
    layout: Layouts.LEARNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager, RoleNameEnum.Learner, RoleNameEnum.Guest],
  },
  '/[slug]/manage': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/onboarding/welcome': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/onboarding/integration': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/onboarding/survey': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/onboarding/validate': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/onboarding/analysis': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/onboarding/generate': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/email-templates': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/email-templates/[id]': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/submissions': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/analytics': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/submissions/[submissionId]': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/learning-path': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/learning-path/blocks/new/[blockType]': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/learning-path/blocks/[blockId]/edit': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/manage/[cohortId]/edit': {
    layout: Layouts.OWNER,
    roles: [RoleNameEnum.Owner, RoleNameEnum.SuccessManager],
  },
  '/[slug]/articles/[blockSlug]': {
    layout: Layouts.PUBLIC,
    roles: [],
  },
  '/pwa/offline': {
    layout: Layouts.PUBLIC,
    roles: [],
  },
}

export const getPathLayout = (pathname: string): Layout => pathSettings[pathname]?.layout
export const getPathRoles = (pathname: string): string[] => pathSettings[pathname]?.roles
