const SvgGlobeHemisphereWest = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5ZM13.5 8c0 .706-.135 1.405-.4 2.059L10.306 8.34a.995.995 0 0 0-.39-.14L8.49 8.01a1.007 1.007 0 0 0-1 .491h-.545l-.238-.491a.994.994 0 0 0-.687-.542l-.5-.108.489-.859h1.044c.17 0 .335-.043.483-.125l.766-.423c.067-.037.13-.082.187-.133l1.682-1.521a.995.995 0 0 0 .204-1.23l-.023-.04A5.507 5.507 0 0 1 13.5 8Zm-11 0c0-.817.182-1.625.534-2.362l.709 1.891a1 1 0 0 0 .726.625l1.34.289.237.495a1.006 1.006 0 0 0 .9.562h.093l-.452 1.014a1 1 0 0 0 .179 1.086l.008.009L8 12.87l-.121.625A5.507 5.507 0 0 1 2.5 8Z"
      opacity={0.4}
    />
  </svg>
)
export default SvgGlobeHemisphereWest
