const SvgIconChevronDown = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.414 6.414 8 13.828.586 6.414l2.828-2.828L8 8.172l4.586-4.586 2.828 2.828Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconChevronDown
