/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames'

import { LearningCommunity } from '@/graphql/generated'

type Props = {
  size?: 'sm' | 'md' | 'lg'
  learningCommunity?: Partial<LearningCommunity>
}

const LCLogo: React.FC<React.PropsWithChildren<Props>> = ({
  size = 'sm',
  learningCommunity: { name, logoUrl } = { name: 'n/a' },
}) => {
  const classes = classNames(
    {
      'w-5 h-5': size === 'sm',
      'w-8 h-8': size === 'md',
      'w-12 h-12': size === 'lg',
    },
    'rounded-sm object-cover',
  )
  const PLACEHOLDER = `https://source.boringavatars.com/beam/120/${name}?colors=000,999,333`

  return (
    <div className={`${classes} shrink-0 justify-center align-middle text-xs`}>
      <img src={logoUrl || PLACEHOLDER} alt={name} className={classes} />
    </div>
  )
}

export default LCLogo
