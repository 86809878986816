import { t } from '@lingui/macro'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Avatar, Button, Dropdown, Logo, Text } from '@/components'
import {
  IconBook,
  IconChevronDown,
  IconFire,
  IconFrame,
  IconHelpCircle,
  IconSettings,
  IconUser,
  IconUsers,
} from '@/components/Icons'
import LCLogo from '@/components/LCLogo'
import CohortNavigation from '@/compositions/OwnerSideMenu/CohortNavigation'
import { Routes } from '@/constants'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import useAuth from '@/hooks/useAuth'
import useFlags from '@/hooks/useFlags'

const SideMenu: React.FC<React.PropsWithChildren> = () => {
  const { pathname, query } = useRouter()
  const userContext = useCurrentUserContext()
  const { logOut } = useAuth()
  const learningCommunityContext = useLearningCommunityContext()
  const { viewCreatorOnboarding } = useFlags()

  const getRoute = (route: Routes): string => `/${query?.slug}${route}`

  const menu = [
    {
      icon: IconHelpCircle,
      path: getRoute(Routes.APPLICATION_FORM),
      label: t`Application form`,
      visible: true,
    },
    {
      icon: IconUsers,
      path: getRoute(Routes.CRM),
      label: t`CRM`,
      visible: true,
    },
    {
      icon: IconBook,
      path: getRoute(Routes.WAITING_LIST),
      label: t`Waiting list`,
      visible: viewCreatorOnboarding,
    },
    {
      icon: IconUser,
      path: getRoute(Routes.TEAM),
      label: t`Team`,
      visible: true,
    },
    {
      icon: IconFrame,
      path: getRoute(Routes.INTEGRATIONS),
      label: t`Integrations`,
      visible: true,
    },
    {
      icon: IconSettings,
      path: getRoute(Routes.SETTINGS),
      label: t`Settings`,
      visible: true,
    },
  ].filter(item => item.visible)

  const classes = (path: string): { anchor: string; svg: string } => {
    // Remove slug from both `path` and `pathname`
    const [, , , asPath] = path.split('/')
    const [, , , asPathname] = pathname.split('/')
    const isCurrentPath = asPath === asPathname
    const anchor = classNames(
      'rounded-md space-x-2 px-4 hover:bg-black hover:bg-opacity-10 py-2 leading-none mx-2 flex items-center block text-white',
      isCurrentPath ? 'bg-black bg-opacity-10' : '',
    )
    const svg = classNames('icon-sm fill-current mt-0.5 text-white')

    return { anchor, svg }
  }

  const { anchor, svg } = classes(getRoute(Routes.MANAGEMENT))

  return (
    <>
      <div className="px-6 py-3">
        {learningCommunityContext?.learningCommunity ? (
          <LCLogo size="lg" learningCommunity={learningCommunityContext?.learningCommunity} />
        ) : (
          <Logo variant="white" />
        )}
      </div>

      <div className="flex grow flex-col overflow-hidden">
        <div className="no-scrollbar flex grow flex-col overflow-auto">
          <div className="mb-4">
            <Link href={getRoute(Routes.MANAGEMENT)} className={anchor}>
              <IconFire className={svg} />
              <div>{t`Welcome`}</div>
            </Link>
          </div>

          <div className="mb-2 px-6 font-medium uppercase tracking-widest text-white text-sm">{t`Learning community`}</div>

          <ul className="space-y-0.5">
            {menu.map(({ path, icon: Icon, label }, index) => {
              const { anchor, svg } = classes(path)

              return (
                <li key={index}>
                  <Link href={path} className={anchor}>
                    <Icon className={svg} />
                    <div>{label}</div>
                  </Link>
                </li>
              )
            })}
          </ul>

          <CohortNavigation cohorts={learningCommunityContext?.learningCommunity?.cohorts ?? []} />
        </div>
        <div className="h-px rounded-full shadow shadow-purple-light"></div>
      </div>

      <div className="left-0 w-full flex-none p-6">
        <Dropdown
          label={
            userContext?.currentUser ? (
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4 text-white">
                  <Avatar
                    size="lg"
                    url={userContext?.currentUser?.me?.avatarUrl ?? ''}
                    name={userContext?.currentUser?.me?.fullName ?? ''}
                  />
                  <Text className="text-white">{userContext?.currentUser?.me?.fullName}</Text>
                </div>

                <div>
                  <IconChevronDown className={classNames('icon-sm fill-current ml-2 text-white')} />
                </div>
              </div>
            ) : (
              <></>
            )
          }
        >
          <Button variant="inline" align="start" onClick={() => logOut(Routes.LOGIN)}>
            <div className="py-2">{t`Log out`}</div>
          </Button>
        </Dropdown>
      </div>
    </>
  )
}

export default SideMenu
