import { LogoutIcon } from '@heroicons/react/outline'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Avatar, Logo } from '@/components'
import { IconMenu, IconRoute, IconUser } from '@/components/Icons'
import { Close } from '@/components/Icons/v2'
import LCLogo from '@/components/LCLogo'
import { Routes } from '@/constants'
import { LearnerMenu } from '@/containers/Layouts/Learner/Learner'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import useAuth from '@/hooks/useAuth'
import { usePaths } from '@/hooks/usePaths'
import { SidebarMenuType } from '@/hooks/useSidebarMenus'

import GiveUsFeedback from '../Common/GiveUsFeedback'

type Props = {
  avatarUrl: string
}

const MobileMenu: React.FC<React.PropsWithChildren<Props>> = ({ avatarUrl }) => {
  const { isSpacePath } = usePaths()
  const [menuOpened, setMenuOpened] = useState(false)
  const { asPath } = useRouter()
  const { logOut } = useAuth()
  const learningCommunityContext = useLearningCommunityContext()

  const extraMenus: SidebarMenuType = {
    cohortMenus: [
      {
        path: `/[slug]${Routes.LEARN}/[cohortId]${Routes.PROFILE}`,
        icon: IconUser,
        label: t`View profile`,
      },
    ],
    learningCommunityMenus: [
      {
        path: Routes.LEARNING_COMMUNITIES,
        icon: IconRoute,
        label: t`Learning communities`,
        visible: true,
      },
    ],
  }

  useEffect(() => {
    document.body.style.overflow = menuOpened ? 'hidden' : 'unset'
  }, [menuOpened])

  useEffect(() => {
    setMenuOpened(false)
  }, [asPath])

  return (
    <>
      <div
        className={classNames('bg-grey-bright md:hidden', {
          hidden: isSpacePath,
        })}
      >
        <div className="flex h-20 w-full items-center px-6">
          <div className="mt-2 grow">
            {learningCommunityContext?.learningCommunity ? (
              <LCLogo size="md" learningCommunity={learningCommunityContext?.learningCommunity} />
            ) : (
              <Logo />
            )}
          </div>

          <div>
            <div className="flex items-center space-x-2">
              <div>
                <button
                  className="flex items-center space-x-2 rounded-full border border-grey-light px-4 py-1.5"
                  onClick={() => setMenuOpened(!menuOpened)}
                >
                  {menuOpened ? <Close className="icon-sm" /> : <IconMenu className="icon-sm" />}
                  {avatarUrl ? <Avatar url={avatarUrl} size="md" /> : null}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {menuOpened ? (
        <div className="fixed left-0 z-50 h-[92vh] w-full overflow-auto bg-grey-bright">
          <ul className="space-y-4 px-5 pb-12">
            <li>
              <div className="-mx-2">
                <LearnerMenu extraMenus={extraMenus} />
              </div>
            </li>

            <li className="border-t border-grey-light" />

            <li className="flex justify-start text-left">
              <GiveUsFeedback />
            </li>

            <li>
              <button
                onClick={() => logOut(Routes.LOGIN)}
                className="flex items-center space-x-1 font-medium text-navy-dark"
              >
                <LogoutIcon className="h-5 w-5" />
                <div>{t`Log out`}</div>
              </button>
            </li>
          </ul>
        </div>
      ) : null}
    </>
  )
}

export default MobileMenu
