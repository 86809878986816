import { t } from '@lingui/macro'
import { useRouter } from 'next/router'

import { IconFrame } from '@/components/Icons'
import MainMenuItem from '@/components/MainMenuItem/MainMenuItem'
import SpaceSymbol from '@/compositions/Spaces/SpaceSymbol'
import useFlags from '@/hooks/useFlags'
import usePermissions from '@/hooks/usePermissions'
import { CustomCohort } from '@/types/custom'

export default function CohortSpacesList({ id, channels }: CustomCohort): JSX.Element {
  const { query, asPath } = useRouter()
  const { viewHome } = useFlags()
  const hasCohortSpaces = channels?.length > 0
  const { isManager, isOwner } = usePermissions()
  const canManageSpaces = isManager || isOwner
  const cohortId = id

  if (!hasCohortSpaces && !viewHome && canManageSpaces)
    return (
      <div className="p-4">
        <p className="text-gray-400 text-sm">{t`No spaces yet.`}</p>
      </div>
    )

  const feedFiltersAsChannels = [
    {
      label: t`Feedback`,
      feedType: 'type',
      value: 'FEEDBACK_REQUEST',
    },
    {
      label: t`Introductions`,
      feedType: 'type',
      value: 'INTRODUCTION',
    },
    {
      label: t`Announcements`,
      feedType: 'category',
      value: 'ANNOUNCEMENT',
    },
  ]

  return (
    <ul className="pl-4">
      {viewHome
        ? feedFiltersAsChannels.map(({ label, feedType, value }) => {
            const href = `/${query.slug}/learn/${cohortId}/dashboard?${feedType}=${value}`

            return (
              <MainMenuItem
                key={label}
                isActive={asPath === href}
                icon={IconFrame}
                href={href}
                label={label as string}
              />
            )
          })
        : null}

      {channels?.map(channel => {
        const { id, viewer, emojiIcon, name } = channel
        const href = `/${query.slug}/learn/${cohortId}/space/${id}`

        return (
          <MainMenuItem
            key={id}
            isActive={asPath === href}
            icon={<SpaceSymbol emojiIcon={emojiIcon} size="sm" />}
            href={href}
            label={name as string}
            indicator={viewer?.hasUnreadMessages}
          />
        )
      })}
    </ul>
  )
}
