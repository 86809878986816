import { Button } from '@/components'

const Print: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className="flex min-h-screen flex-col justify-between">
        <div className="h-full flex-grow pb-16">
          <div>{children}</div>
          <div className="fixed bottom-4 z-40 flex w-full items-center justify-center print:hidden">
            <Button variant="primary" block className="max-w-[480px]" onClick={() => print()}>
              Print
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Print
