import { Switch } from '@headlessui/react'
import { useState } from 'react'

type Props = {
  initialValue: boolean
  onChange: (value: boolean) => void
  label?: string
  // when we have a need we can add colors/sizes prop variations
}

const Toggle: React.FC<React.PropsWithChildren<Props>> = ({ label, initialValue, onChange }) => {
  const [enabled, setEnabled] = useState(initialValue)

  const handleOnChange = (value: boolean): void => {
    onChange(value)
    setEnabled(value)
  }

  return (
    <Switch.Group>
      {label ? (
        <Switch.Label className="relative text-grey-dark" passive>
          {label}
        </Switch.Label>
      ) : null}

      <Switch
        checked={enabled}
        onChange={handleOnChange}
        className={`${
          enabled ? 'bg-purple-dark' : 'bg-grey-light'
        } relative inline-flex h-6 w-[42px] items-center rounded-full shadow-sm`}
      >
        <span
          className={`${
            enabled ? 'translate-x-5' : 'translate-x-0.5'
          } inline-block h-5 w-5 transform rounded-full bg-white transition duration-200 ease-in-out`}
        />
      </Switch>
    </Switch.Group>
  )
}

export default Toggle
