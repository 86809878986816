const SvgIconFrame = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 12v3H4v-3H1v-2h3V6H1V4h3V1h2v3h4V1h2v3h3v2h-3v4h3v2h-3v3h-2v-3H6Zm0-2h4V6H6v4Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgIconFrame
