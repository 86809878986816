import classNames from 'classnames'

type Props = {
  block?: boolean
  type?: 'success' | 'info' | 'secondary' | 'warning'
  size?: 'sm' | 'md'
  custom?: {
    bg: string
    text: string
  }
  uppercase?: boolean
  align?: 'start' | 'center' | 'end'
}

const Tag: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  custom,
  block,
  type,
  uppercase = true,
  size = 'md',
  align,
}) => {
  const classes = classNames('rounded-full font-sans capsize font-medium', {
    'px-2 py-1 text-sm-none': size === 'sm',
    'px-3 py-1.5 text-sm-none': size === 'md',
    'text-white bg-green-dark': type === 'success',
    'text-purple-dark bg-purple-bright': type === 'info',
    'text-yellow-dark bg-yellow-light': type === 'warning',
    'bg-grey-extra-light text-navy-light': type === 'secondary',
    uppercase: uppercase,
    'items-center flex': align === 'center',
    'items-start flex': align === 'start',
    'items-end flex': align === 'end',
  })
  const style = { ...(custom ? { backgroundColor: custom.bg, color: custom.text } : {}) }

  return (
    <div className={block ? 'block' : 'inline-block'}>
      <div className={classes} style={style}>
        {children}
      </div>
    </div>
  )
}

export default Tag
