import classNames from 'classnames'

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  variant?: 'normal' | 'white'
}

const Logo: React.FC<React.PropsWithChildren<Props>> = ({ size = 'md', variant = 'normal' }) => {
  const classes = classNames({
    'text-white': variant === 'white',
    'text-purple-base': variant === 'normal',
    'w-4 h-4': size === 'xs',
    'w-5 h-5': size === 'sm',
    'w-8 h-8': size === 'md',
    'w-12 h-12': size === 'lg',
  })

  return (
    <div className="inline-flex items-center">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" className={classes}>
        <g>
          <path
            d="M38.3925 19.2105C43.6944 19.2105 47.9925 14.9124 47.9925 9.6105C47.9925 4.30856 43.6944 0.010498 38.3925 0.010498C33.0905 0.010498 28.7925 4.30856 28.7925 9.6105C28.7925 14.9124 33.0905 19.2105 38.3925 19.2105Z"
            fill="currentColor"
          />
          <path
            d="M9.6 47.9895C14.9019 47.9895 19.2 43.6914 19.2 38.3895C19.2 33.0876 14.9019 28.7895 9.6 28.7895C4.29807 28.7895 0 33.0876 0 38.3895C0 43.6914 4.29807 47.9895 9.6 47.9895Z"
            fill="currentColor"
          />
          <path
            d="M45.1875 31.605C44.3595 30.7783 43.3862 30.1112 42.3165 29.637H42.3075C41.9091 29.4558 41.4986 29.3024 41.079 29.178L29.874 24.999C28.3032 24.413 26.8768 23.496 25.6917 22.3101C24.5066 21.1242 23.5905 19.6972 23.0055 18.126L18.675 6.50849C18.6015 6.29249 18.525 6.07649 18.435 5.86349L18.3525 5.68499C17.778 4.39815 16.9273 3.25357 15.8607 2.33252C14.794 1.41148 13.5377 0.736563 12.1809 0.355695C10.8241 -0.0251737 9.40008 -0.102654 8.00996 0.128763C6.61983 0.36018 5.2977 0.894815 4.13746 1.6947C2.97721 2.49459 2.0073 3.54012 1.29659 4.75704C0.585889 5.97396 0.151818 7.33242 0.0252104 8.73598C-0.101397 10.1395 0.0825651 11.5538 0.564026 12.8782C1.04549 14.2027 1.81264 15.4049 2.81101 16.3995C3.66301 17.253 5.54701 18.3105 6.79651 18.7995L18.12 23.013C19.6903 23.5995 21.1162 24.5166 22.3012 25.7021C23.4863 26.8876 24.4027 28.314 24.9885 29.8845L29.4345 41.817C29.466 41.898 29.493 41.979 29.526 42.06L29.628 42.3315L29.6475 42.3225C30.2224 43.6091 31.0734 44.7533 32.1402 45.674C33.207 46.5947 34.4634 47.2692 35.8202 47.6497C37.1769 48.0302 38.6009 48.1074 39.9909 47.8757C41.3808 47.644 42.7027 47.1091 43.8628 46.3091C45.0228 45.5091 45.9924 44.4635 46.7029 43.2466C47.4134 42.0296 47.8473 40.6712 47.9737 39.2677C48.1002 37.8643 47.9161 36.4502 47.4346 35.1259C46.953 33.8016 46.1859 32.5995 45.1875 31.605Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </div>
  )
}

export default Logo
