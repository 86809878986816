const SvgLockSimple = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M13 5h-2V3.5a3 3 0 0 0-6 0V5H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1ZM6 3.5a2 2 0 1 1 4 0V5H6V3.5Z"
      opacity={0.4}
    />
  </svg>
)
export default SvgLockSimple
