import { createContext, useContext, useState } from 'react'

type NavigationContextProviderProps = {
  children: React.ReactNode
}
type NavigationContextProps = {
  isResourcesButtonVisible: boolean
  isBlocksButtonVisible: boolean
  isResourcesPanelOpened: boolean
  isBlocksPanelOpened: boolean
  setResourcesButtonVisible: (isResourcesButtonVisible: boolean) => void
  setBlocksButtonVisible: (isResourcesButtonVisible: boolean) => void
  setResourcesPanelOpened: (isResourcesPanelOpened: boolean) => void
  setBlocksPanelOpened: (isResourcesPanelOpened: boolean) => void
}

export const NavigationContext = createContext<NavigationContextProps | undefined>(undefined)

export const useNavigationContext = (): NavigationContextProps | undefined =>
  useContext<NavigationContextProps | undefined>(NavigationContext)

export const NavigationContextProvider: React.FC<React.PropsWithChildren<NavigationContextProviderProps>> = ({
  children,
}) => {
  const [isResourcesButtonVisible, setResourcesButtonVisible] = useState(false)
  const [isBlocksButtonVisible, setBlocksButtonVisible] = useState(false)
  const [isResourcesPanelOpened, setResourcesPanelOpened] = useState(false)
  const [isBlocksPanelOpened, setBlocksPanelOpened] = useState(false)

  const navigationContext = {
    isResourcesButtonVisible,
    isBlocksButtonVisible,
    setResourcesButtonVisible,
    setBlocksButtonVisible,
    isResourcesPanelOpened,
    isBlocksPanelOpened,
    setResourcesPanelOpened,
    setBlocksPanelOpened,
  }

  return <NavigationContext.Provider value={navigationContext}>{children}</NavigationContext.Provider>
}
