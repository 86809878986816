import { t } from '@lingui/macro'
import Link from 'next/link'
import { useEffect, useRef } from 'react'

import { Button, Spinner, Text } from '@/components'
import { IconBlocks, IconX } from '@/components/Icons'
import { Events } from '@/constants'
import { useNavigationContext } from '@/contexts/NavigationContext'
import { CohortTypeEnum, LearningPathBlockInterface } from '@/graphql/generated'
import useLearningPath from '@/hooks/useLearningPath'
import usePermissions from '@/hooks/usePermissions'
import useRoutes from '@/hooks/useRoutes'
import useTracking from '@/hooks/useTracking'
import { getScreenSize, ScreenSize } from '@/utils/screenSize/getScreenSize'

import BlockIcon from '../../Blocks/BlockIcon'

const SidebarNavigation: React.FC<React.PropsWithChildren> = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { hasManagementAccess, isGuest } = usePermissions()
  const {
    router: { query },
  } = useRoutes()
  const navigationContext = useNavigationContext()
  const { loading, learningPath } = useLearningPath()

  const currentBlockId = query.blockId

  useEffect(() => {
    ref?.current?.scrollIntoView()
  }, [learningPath])

  return (
    <div className="fixed top-0 right-0 z-40 h-screen w-full bg-white py-6 pl-6 pt-8 md:max-w-md">
      <div className="flex w-full items-center space-x-4 pb-6">
        <IconBlocks className="icon-sm flex-none" />
        <Text variant={TextStyle => TextStyle.MEDIUM_BASE_NORMAL} className="w-full" capsize>
          {t`Learning Blocks`}
        </Text>
        <Button
          variant="inline"
          className="flex w-full items-center justify-end pr-6 md:hidden"
          onClick={() => navigationContext?.setBlocksPanelOpened(false)}
          icon={IconX}
        />
      </div>
      <div className="flex h-full flex-col space-y-6 overflow-y-auto pr-6 pb-12">
        {loading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner size="md" />
          </div>
        ) : (
          learningPath?.sections
            .filter(section => {
              if (section.cohort.type === CohortTypeEnum.Cohort) {
                return new Date(section.schedule?.startDate) <= new Date() || hasManagementAccess
              }

              return section
            })
            .map(section => (
              <div key={section.id}>
                <Text variant={TextStyle => TextStyle.MEDIUM_BASE_NORMAL} className="text-grey-dark">
                  {section.title}
                </Text>
                <div className="mt-4 flex flex-col space-y-4">
                  {section.blocks.map(block => (
                    <div ref={currentBlockId === block.id ? ref : undefined} key={block.id}>
                      <Block
                        locked={!!isGuest && !section.isFree}
                        {...block}
                        isCurrentBlock={currentBlockId === block.id}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  )
}

const Block: React.FC<
  React.PropsWithChildren<LearningPathBlockInterface & { isCurrentBlock?: boolean; locked: boolean }>
> = ({ id, title, type, viewerStatus, isCurrentBlock, locked }) => {
  const { trackEvent, withCurrentBlock, withCurrentCohortId, withCurrentLearningCommunity } = useTracking()
  const navigationContext = useNavigationContext()
  const {
    routes: { LEARN },
  } = useRoutes()

  const handleClick = (): void => {
    trackEvent(Events.CLICKED_ON_BLOCK, withCurrentBlock(), withCurrentCohortId(), withCurrentLearningCommunity(), {
      location: 'Sidebar Navigation',
      destinationBlockId: id,
    })

    if (getScreenSize() < ScreenSize.MD) {
      navigationContext?.setBlocksPanelOpened(false)
    }
  }

  return (
    <Link
      href={`${LEARN}/blocks/${id}`}
      className="flex items-center space-x-4"
      onClick={handleClick}
      role="button"
      tabIndex={0}
      passHref
    >
      <BlockIcon
        blockType={type.identifier}
        isCurrentBlock={isCurrentBlock}
        isBlockCompleted={!!viewerStatus.markedAsDoneAt}
        locked={locked}
      />
      <div>
        <Text>{title}</Text>
        <Text className="text-grey-dark">{type.displayValue}</Text>
      </div>
    </Link>
  )
}

export default SidebarNavigation
