const SvgIconFire = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 24 24" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.5 13.5C22.5 19.299 17.799 24 12 24S1.5 19.299 1.5 13.5c0-5.25 4.5-9 4.5-9h3v3a1.5 1.5 0 1 0 3 0V0h3s7.5 4.5 7.5 13.5ZM15 18a3 3 0 1 1-6 0c0-3.75 3-6 3-6s3 2.25 3 6Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconFire
