const SvgIconBlocks = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      d="M4.707 12.707 8 16l3.293-3.293L8 9.414l-3.293 3.293Zm-1.414-1.414L6.586 8 3.293 4.707 0 8l3.293 3.293Zm1.414-8L8 0l3.293 3.293L8 6.586 4.707 3.293Zm8 1.414L9.414 8l3.293 3.293L16 8l-3.293-3.293Z"
    />
  </svg>
)

export default SvgIconBlocks
