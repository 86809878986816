import { isAfter, isBefore, isEqual } from 'date-fns'

type Props = {
  date: Date
  compareToStart: Date
  compareToEnd: Date
}

export const isBetween = ({ date, compareToStart, compareToEnd }: Props): boolean => {
  return (
    (isAfter(date, compareToStart) && isBefore(date, compareToEnd)) ||
    isEqual(date, compareToStart) ||
    isEqual(date, compareToEnd)
  )
}
