import { gql, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { CohortContextProvider } from '@/contexts/CohortContext'
import { LearningCommunityContextProvider } from '@/contexts/LearningCommunityContext'
import { Cohort } from '@/graphql/generated'
import { Layout } from '@/types/custom'

import SideMenu from './SideMenu'

const Owner: Layout = ({ children, headerComponent }) => {
  const { query, isReady } = useRouter()
  const { data: currentLearningCommunity } = useQuery(GET_CURRENT_LEARNING_COMMUNITY, {
    skip: !query?.slug || !isReady,
    variables: {
      slug: query?.slug,
    },
  })

  const currentCohort = query?.cohortId
    ? currentLearningCommunity?.learningCommunity?.cohorts.find((cohort: Cohort) => cohort.id === query.cohortId)
    : null

  return (
    <LearningCommunityContextProvider learningCommunity={currentLearningCommunity?.learningCommunity}>
      <CohortContextProvider cohort={currentCohort ?? undefined}>
        <Script
          id="intercom"
          dangerouslySetInnerHTML={{
            __html: `
            window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "i4dm3k0u"
            };

            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/i4dm3k0u';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `,
          }}
        />

        <div className="flex">
          <div className="sticky top-0 flex h-screen flex-col bg-purple-dark p-3" style={{ flex: '0 0 300px' }}>
            <SideMenu />
          </div>

          <div className="w-creator-screen grow bg-white py-8 px-16">
            <div className="flex items-center justify-end">{headerComponent ? headerComponent : null}</div>
            {children}
          </div>
        </div>
      </CohortContextProvider>
    </LearningCommunityContextProvider>
  )
}

export default Owner

export const GET_CURRENT_LEARNING_COMMUNITY = gql`
  query LearningCommunity($slug: String!) {
    learningCommunity(slug: $slug) {
      id
      name
      slug
      themeColor
      logoUrl
      supportEmail
      referenceTopic
      temporaryLearningPathOutline
      cohorts {
        id
        name
        type
        status
        learningPath {
          project {
            id
          }
          sections {
            id
          }
        }
      }
      topicSuggestions {
        topic
      }
      integrations {
        google {
          youtubeAccount {
            intent
          }
        }
        zoom {
          addedBy {
            fullName
          }
        }
      }
      validationSurvey {
        id
        isPublished
        submissions {
          totalCount
        }
      }
    }
  }
`
