import { gql, useMutation } from '@apollo/client'
import { Popover } from '@headlessui/react'
import { t } from '@lingui/macro'
import { Field, Form, Formik, FormikValues } from 'formik'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { object, string } from 'yup'

import { Button, EmojiPicker, Modal, Switch, Text, Toast } from '@/components'
import { IconFrame } from '@/components/Icons'
import { GlobeHemisphereWest, LockSimple } from '@/components/Icons/v2'
import { ErrorMessages } from '@/constants'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import { Channel, ChannelSourceTypeEnum, ChannelTypeEnum } from '@/graphql/generated'
import getRandomEmoji from '@/utils/emojis/getRandomEmoji'

const spaceFormSchema = object().shape({
  name: string().required(ErrorMessages.REQUIRED_FIELD),
})

type Props = {
  source: ChannelSourceTypeEnum
  sourceId: string
  onModalClose?: () => void
  channel?: Channel
}

export default function ManageSpaceModal({ source, sourceId, onModalClose, channel }: Props): JSX.Element {
  const { push, query } = useRouter()
  const learningCommunityContext = useLearningCommunityContext()
  const isEditing = !!channel

  const [createChannel, { loading: creatingChannel }] = useMutation(CREATE_CHANNEL_MUTATION, {
    onCompleted: data => {
      if (!data?.createChannel?.errors) {
        Toast({
          message: t`Space created`,
          description: t`Your new space “${data?.createChannel?.channel?.name}” has been created`,
          type: 'success',
        })

        onModalClose?.()

        if (source === ChannelSourceTypeEnum.Cohort) {
          push({
            pathname: '/[slug]/learn/[cohortId]/space/[channelId]',
            query: { ...query, cohortId: sourceId, channelId: data?.createChannel?.channel?.id },
          })
        } else {
          push({
            pathname: '/[slug]/space/[channelId]',
            query: { ...query, channelId: data?.createChannel?.channel?.id },
          })
        }

        return
      }

      Toast({
        message: t`Something went wrong. Try again later.`,
        type: 'error',
      })
    },
    refetchQueries: ['LearningCommunity', 'Cohort'],
    awaitRefetchQueries: true,
  })

  const [updateChannel, { loading: updatingChannel }] = useMutation(UPDATE_CHANNEL_MUTATION, {
    onCompleted: data => {
      if (!data?.updateChannel?.errors) {
        Toast({
          message: t`Space updated`,
          type: 'success',
        })

        return
      }

      Toast({
        message: t`Something went wrong. Try again later.`,
        type: 'error',
      })
    },
    refetchQueries: ['LearningCommunity', 'Cohort'],
  })

  async function handleSubmit(data: FormikValues): Promise<void> {
    isEditing
      ? await updateChannel({
          variables: {
            channelId: channel.id,
            attributes: { name: data.name, description: data.description, emojiIcon: data.emojiIcon },
          },
        })
      : await createChannel({ variables: { attributes: { ...data, sourceId, sourceType: source } } })
  }

  const isLoading = creatingChannel || updatingChannel

  const cohortName =
    source === ChannelSourceTypeEnum.Cohort
      ? learningCommunityContext?.learningCommunity?.cohorts.find(cohort => cohort.id === sourceId)?.name
      : ''

  return (
    <Modal size="small" onClose={onModalClose} opened showClose>
      <Formik
        initialValues={{ name: '', description: '', emojiIcon: '', type: ChannelTypeEnum.Space, ...channel }}
        validationSchema={spaceFormSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <div className="space-y-10 p-8">
                <div className="space-y-4">
                  <Text className="text-navy-dark" variant={TextStyle => TextStyle.MEDIUM_XL_NORMAL}>
                    {t`${isEditing ? 'Edit' : 'New'} space`}
                  </Text>

                  {isEditing ? null : (
                    <Text className="text-grey-base" variant={TextStyle => TextStyle.REGULAR_BASE_NONE}>
                      {t`Spaces are where your community comes together to discuss and share.`}
                    </Text>
                  )}
                </div>

                <div className="space-y-2">
                  <div className="font-medium text-navy-dark">{t`Name`}</div>

                  <div className="flex h-12 space-x-2">
                    <div className="shrink">
                      <Popover>
                        {({ close }) => {
                          return (
                            <>
                              <Popover.Button className="flex w-14 items-center justify-center rounded-md bg-grey-bright p-4 text-grey-dark">
                                {values?.emojiIcon ? (
                                  <div className="leading-none">{values.emojiIcon}</div>
                                ) : (
                                  <ShuffleEmojis />
                                )}
                              </Popover.Button>

                              <Popover.Panel className="absolute">
                                <EmojiPicker
                                  onClose={close}
                                  onEmojiClick={data => {
                                    setFieldValue('emojiIcon', data.emoji)
                                    close()
                                  }}
                                />
                              </Popover.Panel>
                            </>
                          )
                        }}
                      </Popover>
                    </div>

                    <div className="grow">
                      <Field
                        name="name"
                        placeholder={t`e.g. showcase-work`}
                        className="h-full w-full rounded-md bg-grey-bright px-3 pb-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="font-medium text-navy-dark">{t`Description`}</div>

                  <div className="h-32 items-start">
                    <Field
                      as="textarea"
                      name="description"
                      placeholder={t`What is the purpose of this space? (optional)`}
                      className="h-full w-full rounded-md bg-grey-bright p-3"
                    />
                  </div>
                </div>

                {isEditing ? null : (
                  <div className="flex items-center space-x-24">
                    <div className="space-y-2">
                      <div className="font-medium text-navy-dark">{t`Make mandatory`}</div>
                      <div className="text-grey-base">
                        {t`Mandatory spaces are those where you want everyone in without the option to leave.`}
                      </div>
                    </div>

                    <div>
                      <Switch
                        initialValue={true}
                        onChange={value => {
                          if (value) {
                            setFieldValue('type', ChannelTypeEnum.Space)
                            return
                          }

                          setFieldValue('type', ChannelTypeEnum.PublicOpen)
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="items-center space-y-4 md:flex md:space-y-0">
                  <div className="grow">
                    {source === ChannelSourceTypeEnum.Cohort ? (
                      <div className="flex items-center space-x-1 text-grey-base text-sm">
                        <div>
                          <LockSimple className="h-4 w-4 text-grey-dark" />
                        </div>
                        <div>{t`Visible only for ${cohortName}`}</div>
                      </div>
                    ) : null}

                    {source === ChannelSourceTypeEnum.LearningCommunity ? (
                      <div className="flex items-center space-x-1 text-grey-base text-sm">
                        <div>
                          <GlobeHemisphereWest className="h-4 w-4 text-grey-dark" />
                        </div>
                        <div>{t`Visible to all the community`}</div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <Button type="submit" disabled={isLoading} loading={isLoading} variant="primary">
                      {isEditing ? t`Save changes` : t`Create space`}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

function ShuffleEmojis(): JSX.Element {
  const [emoji, setEmoji] = useState<string | undefined>()

  useEffect(() => {
    setInterval(() => {
      setEmoji(getRandomEmoji())
    }, 3000)
  }, [])

  return <>{emoji ? <div className="leading-none">{emoji}</div> : <IconFrame className="h-4 w-4" />}</>
}

const CREATE_CHANNEL_MUTATION = gql`
  mutation CreateChannel($attributes: ChannelInput!) {
    createChannel(attributes: $attributes) {
      channel {
        id
        name
      }
      errors {
        field
        message
        path
      }
    }
  }
`

const UPDATE_CHANNEL_MUTATION = gql`
  mutation UpdateChannel($channelId: ID!, $attributes: UpdateChannelInput!) {
    updateChannel(channelId: $channelId, attributes: $attributes) {
      channel {
        id
        name
      }
      errors {
        field
        message
        path
      }
    }
  }
`
