import { useApolloClient } from '@apollo/client'
import Cookies from 'js-cookie'

import { Storage } from '@/constants'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'

import useRoutes from '../useRoutes'

type ReturnType = {
  login: (token: string, redirectUrl?: string | null, onFinish?: () => void) => Promise<void>
  isLogged: () => boolean
  logOut: (
    redirectUrl?: string | null,
    options?: {
      onFinish?: () => void
      preserveStore?: boolean
    },
  ) => void
}
const useAuth = (): ReturnType => {
  const userContext = useCurrentUserContext()
  const client = useApolloClient()
  const { pushWithSSR } = useRoutes()

  return {
    login: async (token: string, redirectUrl, onFinish) => {
      Cookies.set(Storage.API_TOKEN, token, {
        expires: 14,
        path: '/',
        sameSite: 'lax',
      })
      if (!redirectUrl) {
        await userContext?.refetchUser()
      }
      onFinish?.()

      if (redirectUrl) {
        pushWithSSR(redirectUrl)
      }
    },
    isLogged: () => {
      return !!userContext?.currentUser?.me?.id && !!Cookies.get(Storage.API_TOKEN)?.length
    },
    logOut: async (redirectUrl, options) => {
      userContext?.setLoggingOut(true)
      Cookies.remove(Storage.API_TOKEN)
      localStorage.removeItem(Storage.CURRENT_LEARNING_COMMUNITY)
      analytics?.reset()
      userContext?.resetUser()

      if (!options?.preserveStore) await client.clearStore()

      options?.onFinish?.()

      if (redirectUrl) {
        pushWithSSR(redirectUrl)
      }
    },
  }
}

export default useAuth
