import { gql, useQuery } from '@apollo/client'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { Logo, Text } from '@/components'
import { IconUsers } from '@/components/Icons'
import LCLogo from '@/components/LCLogo/LCLogo'
import BottomNavigation from '@/compositions/Learner/BottomNavigation'
import SidebarNavigation from '@/compositions/LearningPath/Navigation/SidebarNavigation'
import MobileMenu from '@/compositions/MobileMenu'
import { CohortContextProvider } from '@/contexts/CohortContext'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { LearningCommunityContextProvider, useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import { NavigationContextProvider, useNavigationContext } from '@/contexts/NavigationContext'
import { Query } from '@/graphql/generated'
import useFlags from '@/hooks/useFlags'
import { SidebarMenuType, useSidebarMenus } from '@/hooks/useSidebarMenus'
import useTitleWithNotifications from '@/hooks/useTitleWithNotifications'
import { Layout } from '@/types/custom'

import LearningCommunitySwitcher from '../LearningCommunitySwitcher'
import SidebarMenu from './SidebarMenu'

const Learner: Layout = ({ children }) => {
  const pageTitle = useTitleWithNotifications()

  const { query, isReady } = useRouter()
  const userContext = useCurrentUserContext()
  const { data: currentLearningCommunity } = useQuery<Pick<Query, 'learningCommunity'>>(
    GET_CURRENT_LEARNING_COMMUNITY,
    {
      skip: !query?.slug || !isReady,
      variables: {
        slug: query?.slug,
        onlyMine: true,
      },
    },
  )

  const currentCohort = query?.cohortId
    ? currentLearningCommunity?.learningCommunity?.cohorts.find(cohort => cohort.id === query.cohortId)
    : null

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>

      <LearningCommunityContextProvider learningCommunity={currentLearningCommunity?.learningCommunity ?? undefined}>
        <CohortContextProvider cohort={currentCohort ?? undefined}>
          <div>
            <MobileMenu avatarUrl={userContext?.currentUser?.me?.avatarUrl ?? ''} />

            <div className="fixed top-0 z-50 hidden h-screen w-[280px] flex-col items-start space-y-4 bg-white px-3 py-4 md:flex">
              <div className="w-full">
                <SidebarLogo />
              </div>

              <div className="w-full grow overflow-hidden">
                <LearnerMenu showAskMyYtChannel={currentLearningCommunity?.learningCommunity?.hasAskMyYtChannel} />
              </div>

              <div className="w-full">
                <BottomNavigation />
              </div>
            </div>

            {/* 280px because it's the width of the left sidebar */}
            <div className="grow md:ml-[280px] md:max-w-[calc(100vw-280px)]">
              <NavigationContextProvider>
                <LearnerInnerContent>{children}</LearnerInnerContent>
              </NavigationContextProvider>
            </div>
          </div>
        </CohortContextProvider>
      </LearningCommunityContextProvider>
    </>
  )
}

const LearnerInnerContent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { pathname } = useRouter()
  const navigationContext = useNavigationContext()
  const isNavigationPanelOpened = navigationContext?.isBlocksPanelOpened

  // Some pages might need to take advantage of the full width of the container.
  // Decided to go on a path / routes basis to detect which ones do.
  const isFullWidth = [
    '/[slug]/space/[channelId]',
    '/[slug]/learn/[cohortId]/space/[channelId]',
    '/[slug]/learn/[cohortId]/space/discover',
    '/[slug]/ask',
  ].includes(pathname)

  return (
    <div className="justify-between md:flex">
      <div className="flex h-full grow">
        <div
          className={classNames('w-full', isFullWidth ? '' : 'md:px-11', isNavigationPanelOpened ? 'lg:mr-112' : '')}
        >
          {children}
        </div>
        {isNavigationPanelOpened ? <SidebarNavigation /> : null}
      </div>
    </div>
  )
}

function SidebarLogo(): JSX.Element {
  const learningCommunityContext = useLearningCommunityContext()

  return (
    <LearningCommunitySwitcher>
      <div className="flex items-center space-x-3 rounded-md px-3 py-2 text-left hover:bg-grey-bright">
        {learningCommunityContext?.learningCommunity ? (
          <LCLogo size="md" learningCommunity={learningCommunityContext?.learningCommunity} />
        ) : (
          <div className="rounded-md bg-grey-bright p-2 leading-none">
            <Logo size="sm" />
          </div>
        )}

        <div className="grow space-y-1">
          <Text variant={TextStyle => TextStyle.MEDIUM_SM_SNUG} className="leading-none">
            {learningCommunityContext?.learningCommunity?.name ?? 'Coleap'}
          </Text>
        </div>

        <div>
          <ChevronDownIcon className="h-4 w-4" />
        </div>
      </div>
    </LearningCommunitySwitcher>
  )
}

export function LearnerMenu({
  extraMenus,
  showAskMyYtChannel,
}: {
  extraMenus?: SidebarMenuType
  showAskMyYtChannel?: boolean
}): JSX.Element {
  const { pathname } = useRouter()
  const { viewHome } = useFlags()
  const mainMenus = useSidebarMenus({ viewHome, showAskMyYtChannel })

  if (pathname === '/')
    // Currently just a placeholder
    return (
      <div>
        <div className="flex items-center space-x-3 rounded-sm bg-grey-bright px-3 py-2">
          <div>
            <IconUsers className="mt-px h-4 w-4" />
          </div>

          <div className="grow">{t`My communities`}</div>
        </div>
      </div>
    )

  const menus: SidebarMenuType = {
    cohortMenus: [...mainMenus.cohortMenus, ...(extraMenus?.cohortMenus ?? [])],
    learningCommunityMenus: [...mainMenus.learningCommunityMenus, ...(extraMenus?.learningCommunityMenus ?? [])],
  }

  return <SidebarMenu menus={menus} />
}

export const GET_CURRENT_LEARNING_COMMUNITY = gql`
  query LearningCommunity($slug: String!, $onlyMine: Boolean) {
    learningCommunity(slug: $slug) {
      id
      name
      slug
      locale
      logoUrl
      supportEmail
      hasAskMyYtChannel
      cohorts {
        id
        name
        hasDynamicSquads
        hasAiProcessing
        viewer {
          applicant {
            paymentLink
          }
        }
        people {
          id
          fullName
          firstName
          jobDescription
          avatarUrl
          timezone
          location
          pronouns
        }
        aiChannels: channels(onlyMine: $onlyMine, type: [AI]) {
          id
          name
          type
          emojiIcon
          viewer {
            hasUnreadMessages
          }
        }
        channels(onlyMine: $onlyMine, type: [SPACE, PUBLIC_OPEN, PRIVATE_CLOSED]) {
          id
          name
          type
          emojiIcon
          users {
            id
            avatarUrl
            firstName
          }
          viewer {
            hasUnreadMessages
          }
        }
      }
      channels(onlyMine: $onlyMine) {
        id
        name
        type
        emojiIcon
        users {
          id
          avatarUrl
          firstName
        }
        viewer {
          hasUnreadMessages
        }
      }
    }
  }
`

export default Learner
