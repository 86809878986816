import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { Float } from 'headlessui-float-react'
import { Children, cloneElement, ReactElement } from 'react'

import { IconChevronDown } from '@/components/Icons'

type Props = {
  includeContainer?: boolean
  includeArrow?: boolean
  fullWidth?: boolean
  shouldShowMenu?: boolean
  dropdownWidth?: string
  dropdownHeight?: string
  label: string | JSX.Element
  title?: string
  position?: 'left' | 'right'
  fixedHeight?: boolean
  ySpacing?: boolean
  withShadow?: boolean
  dataProps?: Record<string, unknown>
  hasLink?: boolean
}

const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  includeContainer,
  includeArrow,
  label,
  title,
  position,
  fullWidth,
  dropdownWidth = 'w-56',
  dropdownHeight = 'h-full',
  shouldShowMenu = false,
  fixedHeight,
  ySpacing = true,
  withShadow = false,
  dataProps,
  hasLink = false,
}) => {
  const dropdownClasses = classNames(
    'bg-white px-2 bg-white overflow-y-scroll no-scrollbar divide-y divide-grey-dark rounded-lg focus:outline-none',
    withShadow ? 'shadow-hover' : 'ring-1 ring-grey-light',
    dropdownWidth,
    dropdownHeight,
    position === 'right' ? 'right-0' : 'left-0',
    fixedHeight && !dropdownHeight ? 'max-h-[300px]' : 'max-h-[85vh]',
    ySpacing ? 'py-2' : '',
  )

  return (
    <Menu {...dataProps} as="div" className={classNames('relative inline-block text-left', fullWidth ? 'w-full' : '')}>
      {() => (
        <Float
          placement="bottom-start"
          offset={8}
          strategy="fixed"
          flip
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          {...(shouldShowMenu ? { show: shouldShowMenu } : null)}
        >
          <Menu.Button
            className={classNames(
              ' w-full items-center justify-between',
              includeContainer
                ? 'flex h-10 rounded-md border border-grey-extra-light bg-white px-4 py-2 shadow-btn-white capsize hover:bg-grey-bright'
                : 'inline-flex',
            )}
          >
            {label}
            {includeContainer || includeArrow ? <IconChevronDown className="icon-sm ml-2" /> : null}
          </Menu.Button>

          <Menu.Items className={dropdownClasses}>
            <div>
              {title ? <div className="p-3 font-medium">{title}</div> : null}

              {Children.map(children, child => (
                <Menu.Item as={hasLink ? undefined : 'div'}>
                  {child
                    ? cloneElement(child as ReactElement<unknown>, {
                        // TypeScript is not liking `props`, will try to fix this later.
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        className: `${child.props.className} text-left w-full px-3 py-2 rounded-base hover:bg-grey-extra-light cursor-pointer`,
                      })
                    : null}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Float>
      )}
    </Menu>
  )
}

export default Dropdown
