const SvgIconSettings = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 0H7l-.55 2.202a5.964 5.964 0 0 0-1.453.602L3.05 1.636 1.636 3.05l1.168 1.947c-.26.45-.464.938-.602 1.452L0 7v2l2.202.55c.138.515.341 1.002.602 1.453L1.636 12.95l1.414 1.414 1.947-1.168c.45.26.938.465 1.452.602L7 16h2l.55-2.202a5.961 5.961 0 0 0 1.453-.602l1.947 1.168 1.414-1.414-1.168-1.947c.26-.45.465-.938.602-1.452L16 9V7l-2.202-.55a5.961 5.961 0 0 0-.602-1.453l1.168-1.947-1.414-1.414-1.947 1.168a5.964 5.964 0 0 0-1.452-.602L9 0ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconSettings
