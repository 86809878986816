import { useApolloClient } from '@apollo/client'
import { t } from '@lingui/macro'
import { useRouter } from 'next/router'
import { RefObject, useRef, useState } from 'react'
import useBus from 'use-bus'

import { Button } from '@/components'
import MainMenuItem from '@/components/MainMenuItem/MainMenuItem'
import ManageSpaceModal from '@/compositions/Spaces/ManageSpaceModal'
import { UseBusEvents } from '@/constants'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import { ChannelSourceTypeEnum, ChannelTypeEnum } from '@/graphql/generated'
import useDirectMessages from '@/hooks/useDirectMessages'
import { SidebarMenuType } from '@/hooks/useSidebarMenus'
import { CustomCohort } from '@/types/custom'

import DirectMessages from '../DirectMessages'
import CohortMenuContainer from './CohortMenuContainer'
import CohortSpacesList from './CohortSpacesList'
import LearningCommunitySpacesList from './LearningCommunitySpacesList'
import { SpacesListContainer } from './SpacesListContainer'

export default function SidebarMenu({ menus }: { menus: SidebarMenuType }): JSX.Element {
  const learningCommunityContext = useLearningCommunityContext()
  const { query, pathname, asPath } = useRouter()
  const client = useApolloClient()
  const menuRef = useRef<HTMLDivElement>(null)
  const directMessagesRef = useRef<HTMLDivElement>(null)
  const { openChannel } = useDirectMessages()
  const [newChannelProps, setNewChannelProps] = useState<
    { source: ChannelSourceTypeEnum; sourceId: string } | undefined
  >()

  useBus(UseBusEvents.NEW_MESSAGE_RECEIVED, action => {
    const message = action.payload

    if (message?.channel?.id === query?.channelId) return

    client.cache.modify({
      id: client.cache.identify({
        id: message?.channel?.id,
        __typename: 'Channel',
      }),
      fields: {
        viewer: currentViewer => {
          return { ...currentViewer, hasUnreadMessages: true }
        },
      },
    })
  })

  if (!learningCommunityContext?.learningCommunity) return <></>

  const isMenuOverflown = (ref: RefObject<HTMLElement>): boolean => {
    if (!ref?.current) return false
    const el = ref.current

    return el.scrollHeight > el.offsetHeight || el.scrollWidth > el.clientWidth
  }

  function handleNewCohortChannel(cohortId: string): void {
    setNewChannelProps({ source: ChannelSourceTypeEnum.Cohort, sourceId: cohortId })
  }

  function handleNewLearningCommunityChannel(): void {
    if (!learningCommunityContext?.learningCommunity?.id) return

    setNewChannelProps({
      source: ChannelSourceTypeEnum.LearningCommunity,
      sourceId: learningCommunityContext?.learningCommunity?.id,
    })
  }

  return (
    <div className="flex h-full flex-col">
      <div className="h-full w-full flex-[1_0_65%] overflow-hidden">
        <div className="no-scrollbar h-full w-full grow overflow-auto" ref={menuRef}>
          <ul className="w-full">
            {menus?.learningCommunityMenus?.map(({ visible, label, path, icon: Icon }, index) => {
              return visible ? (
                <li key={index} data-click-target={label}>
                  <MainMenuItem
                    href={{
                      pathname: path,
                      query,
                    }}
                    label={label}
                    isActive={path === pathname}
                    icon={Icon}
                  />
                </li>
              ) : null
            })}
            <li>
              <SpacesListContainer
                source="learning-community"
                title="Spaces"
                onNewChannel={handleNewLearningCommunityChannel}
              >
                <LearningCommunitySpacesList />
              </SpacesListContainer>
            </li>
          </ul>
          <hr className="my-2 border-grey-extra-light" />
          <div className="space-y-2">
            {learningCommunityContext?.learningCommunity?.cohorts?.map((cohort: CustomCohort) => {
              return (
                <CohortMenuContainer cohort={cohort} key={cohort.id}>
                  <ul className="mt-2 w-full pl-4">
                    {menus?.cohortMenus?.map(({ icon: Icon, label, path, visible }, index) => {
                      const isCurrentPath =
                        // TODO: Using i18n we need another approach for `label === Home`
                        // display Home as active path only if there are no feed filters
                        label === 'Home' ? !query.category && !query.type && path === pathname : path === pathname

                      if (visible) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { type, category, feedEntryId, fromDashboard, ...params } = query
                        return (
                          <li key={index} data-click-target={label}>
                            <MainMenuItem
                              href={{
                                pathname: path,
                                query: {
                                  ...params,
                                  cohortId: cohort.id,
                                },
                              }}
                              label={label}
                              isActive={isCurrentPath && cohort.id === query?.cohortId}
                              icon={Icon}
                            />
                          </li>
                        )
                      }
                    })}
                    {cohort?.hasAiProcessing && !cohort?.aiChannels?.length ? (
                      <Button
                        block
                        variant="inline"
                        className="relative flex items-center space-x-3 rounded-sm px-3 py-1.5 text-grey-dark text-sm hover:bg-grey-bright hover:text-navy-dark"
                        onClick={() =>
                          openChannel(
                            [{ id: process.env.NEXT_PUBLIC_COLEAP_BOT_USER_ID ?? '' }],
                            cohort,
                            ChannelTypeEnum.Ai,
                          )
                        }
                      >
                        <div className="relative flex w-full items-center space-x-3">
                          <div className="font-normal text-sm">🤖</div>
                          <div className="grow truncate font-normal text-sm">AI Assistant</div>
                        </div>
                      </Button>
                    ) : null}
                    {cohort?.aiChannels?.map(aiChannel => (
                      <MainMenuItem
                        key={aiChannel.id}
                        isActive={asPath === `/${query.slug}/learn/${cohort.id}/space/${aiChannel.id}`}
                        icon={<>🤖</>}
                        href={`/${query.slug}/learn/${cohort.id}/space/${aiChannel.id}`}
                        label={t`AI Assistant`}
                        indicator={aiChannel.viewer?.hasUnreadMessages}
                      />
                    ))}
                    <li>
                      <SpacesListContainer
                        source="cohort"
                        title="Spaces"
                        cohortId={cohort.id}
                        onNewChannel={() => handleNewCohortChannel(cohort.id)}
                      >
                        <CohortSpacesList {...cohort} />
                      </SpacesListContainer>
                    </li>
                  </ul>
                </CohortMenuContainer>
              )
            })}
          </div>
        </div>
        {isMenuOverflown(menuRef) ? <div className="h-px rounded-full shadow shadow-grey-light" /> : null}
      </div>
      {!isMenuOverflown(menuRef) ? <hr className="mt-2 border-grey-extra-light" /> : null}
      <div className="no-scrollbar w-full overflow-auto" ref={directMessagesRef}>
        <div className="space-y-6 py-3">
          <DirectMessages />
        </div>
      </div>
      {isMenuOverflown(directMessagesRef) ? <div className="h-px rounded-full shadow shadow-grey-light" /> : null}
      {newChannelProps ? (
        <ManageSpaceModal {...newChannelProps} onModalClose={() => setNewChannelProps(undefined)} />
      ) : null}
    </div>
  )
}
