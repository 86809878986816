import { t } from '@lingui/macro'

import { IconLightningBolt } from '@/components/Icons'

const GiveUsFeedback: React.FC<React.PropsWithChildren> = () => {
  return (
    <div className="talk-to-us cursor-pointer">
      <a className="flex items-center justify-center space-x-2 text-navy-dark hover:text-purple-base">
        <IconLightningBolt className="icon-sm" />
        <div className="font-medium font-sans text-base-none capsize">{t`Help & Feedback`}</div>
      </a>
    </div>
  )
}

export default GiveUsFeedback
