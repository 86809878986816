import { useNotifications } from '@magicbell/magicbell-react'
import { useEffect, useState } from 'react'

import { useCurrentUserContext } from '@/contexts/CurrentUserContext'

const useTitleWithNotifications = (): string => {
  const userContext = useCurrentUserContext()
  const notificationsStore = useNotifications()
  const [title, setTitle] = useState('Coleap')

  useEffect(() => {
    const notificationsCount = (notificationsStore?.unseenCount ?? 0) + (userContext?.unreadChannelsCount ?? 0)

    setTitle(notificationsCount > 0 ? `(${notificationsCount}) Coleap` : 'Coleap')
  }, [userContext?.unreadChannelsCount, notificationsStore?.unseenCount])

  return title
}

export default useTitleWithNotifications
